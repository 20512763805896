@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;500;600;700;800&display=swap');


body {
	font-family: 'Nunito Sans', sans-serif;
}

.btn-service {
	border-radius: 50px;
	background: #3DC8AF;
	font-weight: 700;
	color: #fff;
	font-size: 17px;
	padding: 12px 25px;
}

.notary-navbar {
	box-shadow: 0px 1px 4px 0px #00000040;
}

.notary-navbar .navbar-brand img {
	width: 250px;
}

.notary-navbar .navbar-nav li {
	margin-right: 20px;
}


.notary-navbar .navbar-nav li a {
	font-weight: 600;
}

.section-1 h1 {
	font-size: 60px;
	margin-top: 100px;
}


.section-1 .underline {
	position: relative;
}

.section-1 .underline::after {
	content: "";
	position: absolute;
	left: 0px;
	top: 50px;
	background: #3DC8AF;
	width: 100%;
	height: 20px;
	z-index: -1;
}


p.f-size {
	font-size: 20px;
}

h1.f-size {
	font-size: 40px;
}

.color {
	color: #3DC8AF;
}

.bg-color {
	background: #E1FFFA;
	padding-top: 70px;
	padding-bottom: 70px;
}

footer {
	background: #3DC8AF;
}

.bg-black {
	background: #000;
}

.text-black {
	color: #000;
}


.btn-book {
	border-radius: 50px;
	background: #3DC8AF;
	font-weight: 700;
	color: #fff;
	font-size: 17px;
	padding: 8px 30px;

}


.fw-900 {
	font-weight: 900 !important;
}

.fw-700 {
	font-weight: 700 !important;
}

.vr {
	opacity: 1;
	height: 35px;
	width: 1.5px;
	margin-right: 12px;
	margin-left: 12px;
	align-self: center;
}


/* Service Details */

.bg-service {
	background: url('../images/bg-service.png');
	width: 100%;
	height: 320px;
	background-size: cover;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.breadcrumb-item+.breadcrumb-item::before {
	color: white !important;
}


.bg-violet {
	background: #E1FFFA;
	padding-top: 40px;
	padding-bottom: 40px;
}

.select-form label {
	color: #7C808E;
	font-weight: 600;
}

.select-form input {
	border: 0.5px solid #dbdbdb !important;
	padding-top: 12px;
	padding-bottom: 12px;
	border-radius: 10px;
}

.select-form .btn-secondary {
	background: #DBDBDB;
	padding: 12px 25px;
	border: none;
	color: black;
	font-weight: 600;
}

.select-form .btn-check:checked+.btn {
	background: #3DC8AF;
}

.select-form .btn-secondary:hover {
	background: #3DC8AF;
}

.btn-continue {
	border-radius: 50px;
	background: #3DC8AF;
	font-weight: 700;
	color: #fff;
	font-size: 17px;
	padding: 15px 40px;
}

.text-time {
	font-size: 12px;
}

/* select form */


.service-form label {
	color: #7C808E;
	font-weight: 600;
}

.service-form input.form-control {
	border: 0.5px solid #dbdbdb !important;
	padding-top: 12px;
	padding-bottom: 12px;
	border-radius: 10px;
}


/* about */

.bg-about {
	background: url('../images/about.png');
	width: 100%;
	height: 320px;
	background-size: cover;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}


.choose .card {
	border: 1px solid #A9A9A9 !important;
	padding: 40px 20px;
	border-radius: 10px;
	height: 100%;
}

.choose p.card-text {
	margin-block: 0px;
	color: #757575;
	font-size: 18px;
}

/* contact */

.bg-contact {
	background: url('../images/bg-contact.png');
	width: 100%;
	height: 320px;
	background-size: cover;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}


.contact-card {
	box-shadow: 0px 0px 5px 0px #0000001A;
	padding: 30px 6px;
	border-radius: 10px;
}

.contact-card p {
	color: #757575;
}

.contact-form label {
	color: #7C808E;
	font-weight: 600;
}

.contact-form input.form-control {
	border: 0.5px solid #dbdbdb !important;
	padding-top: 12px;
	padding-bottom: 12px;
	border-radius: 10px;
}

/* privacy */

.bg-privacy {
	background: url('../images/privacy.png');
	width: 100%;
	height: 320px;
	background-size: cover;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}


.choose .card {
	border: 1px solid #dfdfdf !important;
	padding: 40px 20px;
	border-radius: 10px;
	height: 100%;
}

.choose p.card-text {
	margin-block: 0px;
	color: #757575;
	font-size: 18px;
}

.fw-600 {
	font-weight: 600 !important;
	font-size: 17px;
}

/* Faq */

.bg-faq {
	background: url('../images/privacy.png');
	width: 100%;
	height: 320px;
	background-size: cover;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}


.bg-none {
	background: none !important;
}

.accordion .accordion-button {
	font-size: 20px !important;
}

.accordion .accordion-item {
	border: 0px;
	border-radius: 0px;
	border-bottom: 1px solid #D9D9D9;
	padding-top: 10px;
	padding-bottom: 10px;
}


.accordion-button:not(.collapsed)::after {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M5 11h14v2H5z'/%3E%3C/g%3E%3C/svg%3E");
}

.accordion-button::after {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E");
}

/* Sign in */

.bg-sign {
	background: url('../images/sign.png');
	width: 100%;
	height: 320px;
	background-size: cover;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

/* forgot passowrd */

.bg-forgot {
	background: url('../images/forgot.png');
	width: 100%;
	height: 320px;
	background-size: cover;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.f-600 {
	font-weight: 600;
}


.form-control:focus {
	box-shadow: none;
}

.btn:hover {
	border: 1px solid #3DC8AF;
}


/* chat */


.bg-chat {
	background: url('../images/bg-chat.png');
	width: 100%;
	height: 320px;
	background-size: cover;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.bg-chat-color {
	background: #E1FFFA;
	border-radius: 10px;
}

.bg-chat-color .input-group {
	background: white;
	box-shadow: 0px 0px 2px 0px #0000004A;
	border-radius: 30px;
	display: flex;
	align-items: center;
}

.bg-chat-color .input-group input {
	padding-top: 15px !important;
	padding-bottom: 15px !important;
	border-radius: 30px;
}

.bg-chat-color .input-group span img {
	margin-left: 20px;
}

.bg-chat-color .time {
	font-size: 8px;
}

.bg-chat-color .text {
	font-size: 12px;
}


.bg-chat-color .nav-pills .nav-link.active {
	background: white !important;
	color: black !important;
}


.bg-chat-color .nav-pills .nav-link {
	color: black !important;
}


.Border-1 {
	box-shadow: 0px 0px 2px 0px #00000040;
}

.Border-2 {
	border-bottom: 1px solid #DDDDDDE5
}


.msger {
	display: flex;
	flex-flow: column wrap;
	justify-content: space-between;
	width: 100%;
	margin: 25px 0px;
	height: 400px;
	overflow: auto;
}

.msger-header {
	display: flex;
	justify-content: space-between;
	padding: 10px;
	background: #eee;
	color: #666;
}

.msger-chat {
	flex: 1;
	overflow-y: auto;
	padding: 10px;
}

.msger-chat::-webkit-scrollbar {
	width: 6px;
}

.msger-chat::-webkit-scrollbar-track {
	background: #ddd;
}

.msger-chat::-webkit-scrollbar-thumb {
	background: #bdbdbd;
}

.msg {
	display: flex;
	align-items: flex-end;
	margin-bottom: 10px;
}

.msg:last-of-type {
	margin: 0;
}

.msg-img {
	width: 50px;
	height: 50px;
	margin-right: 10px;
	background: #ddd;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	border-radius: 50%;
	object-fit: cover;
}

.msg-bubble {
	max-width: 450px;
	padding: 10px 15px;
	border-radius: 15px;
	background: white;
	box-shadow: 0px 0px 4px 0px #00000040;

}

.msg-info {
	display: flex;
	justify-content: end;
	align-items: center;
	margin-bottom: 6px;
}


.msg-info-time {
	font-size: 0.75em;
}

.left-msg .msg-bubble {
	border-bottom-left-radius: 0;
}

.right-msg {
	flex-direction: row-reverse;
}

.right-msg .msg-bubble {
	background: #3DC8AF;
	box-shadow: 0px 0px 4px 0px #00000040;
	color: #fff;
	border-bottom-right-radius: 0;
}

.right-msg .msg-img {
	margin: 0 0 0 10px;
}


.send-text {
	border-top: 1px solid #DDDDDD;
	padding-top: 20px;
	padding-bottom: 20px;
}

.send-text input {
	padding-top: 15px !important;
	padding-bottom: 15px !important;
	border-radius: 30px !important;
}

.send-text .btn {
	border-radius: 30px !important;
}






/* Media Query */


@media (min-width: 1201px) and (max-width: 1450px) {
	.footer-logo {
		width: 200px;
	}
}

@media (min-width: 1024px) and (max-width: 1200px) {

	.notary-navbar .navbar-brand img {
		width: 150px;
	}

	.btn-service {
		padding: 7px 18px;
	}

	.img-fluid.rounded-start {
		width: 50px;
		height: 50px;
	}

	.section-1 h1 {
		font-size: 50px;
		margin-top: 30px;
	}

	.section-1 .underline::after {

		top: 40px;
	}

	.w-75 {
		width: 100% !important;
	}

	.bg-about,
	.bg-forgot,
	.bg-contact,
	.bg-sign,
	.bg-faq,
	.bg-privacy,
	.bg-service,
	.bg-chat {
		height: 160px;
	}

	.bg-chat-color .rounded-circle {
		width: 40px !important;
		height: 40px !important;
	}

	.Border-2 .rounded-circle {
		width: 40px !important;
		height: 40px !important;
	}
}

@media (min-width: 768px) and (max-width: 1023px) {
	.accordion .accordion-button {
		font-size: 15px;
	}

	.section-1 h1 {
		font-size: 30px;
		margin-top: 30px;
	}

	.w-75 {
		width: 100% !important;
	}

	.section-1 .underline::after {
		top: 22px;
		height: 13px;
	}

	h1.f-size {
		font-size: 30px;
	}

	p.f-size {
		font-size: 16px;
	}

	.img-check {
		width: 25px;
		height: 25px;
	}

	.bg-about,
	.bg-forgot,
	.bg-contact,
	.bg-sign,
	.bg-faq,
	.bg-privacy,
	.bg-service,
	.bg-chat {
		height: 160px;
	}

	.choose p.card-text {
		font-size: 15px;
	}

	.m-img.md {
		margin-top: 25px;
	}

	.contact-card h3.fw-700 {
		font-size: 24px;
	}

	.imageSize {
		width: 50px;
	}

	.Border-2 .rounded-circle {
		width: 30px !important;
		height: 30px !important;
		object-fit: contain !important;
	}

	.bg-chat-color .rounded-circle {
		width: 35px !important;
		height: 35px !important;
	}
}

@media (max-width: 767px) {
	.section-1 h1 {
		font-size: 40px;
		margin-top: 0px;
	}

	.section-1 .underline::after {
		top: 32px;
		height: 13px;
	}

	.w-75 {
		width: 100% !important;
	}

	.m-img {
		margin-top: 15px;
	}

	h1.f-size {
		font-size: 30px;
	}

	.bg-about,
	.bg-forgot,
	.bg-contact,
	.bg-sign,
	.bg-faq,
	.bg-privacy,
	.bg-service,
	.bg-chat {
		height: 160px;
	}

	p.f-size {
		font-size: 16px;
	}

	.contact-card {
		margin-bottom: 20px;
	}

	.contact-card h3.fw-700 {
		font-size: 24px;
	}

	.contact-form a.text-decoration-none img {
		width: 70px;
	}

	.navbar-toggler:focus {
		box-shadow: none;
	}

	.offcanvas-header .btn-close:focus {
		box-shadow: none !important;
	}

	.Border-2 .rounded-circle {
		width: 30px !important;
		height: 30px !important;
	}

}

.avatar-upload {
	position: relative;
	max-width: 205px;
	margin: 20px auto;
}

.avatar-upload .avatar-edit {
	position: absolute;
	right: -29px;
	z-index: 1;
	top: 50%;
}

.avatar-upload .avatar-edit input {
	display: none;
}

.avatar-upload .avatar-preview img {
	width: 192px;
	height: 192px;
	position: relative;
	border-radius: 100%;
	border: 6px solid #0000001a;
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload .avatar-edit input+label:after {
	content: "";
	color: #757575;
	position: absolute;
	top: 10px;
	left: 0;
	right: 0;
	text-align: center;
	margin: auto;
	background: url(/src/assests/images/choose.svg);
	width: 50px;
	background-repeat: no-repeat;
	height: 50px;
}

.card {
	box-shadow: 0px 0px 2px 0px #00000040;
}

.Account_my .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
	background: white;
}

.Account_my .nav-pills .nav-link.active ol .ft {
	color: #3DC8AF;
	font-weight: bold;
	text-decoration: underline;
	text-underline-offset: 5px;
}

.Account_my .badge img.img-fluid {
	transform: rotate(180deg);
}

.avatar-upload {
	position: relative;
	max-width: 205px;
	margin: 20px auto;
}

.avatar-upload .avatar-edit {
	position: absolute;
	right: -29px;
	z-index: 1;
	top: 50%;
}

.avatar-upload .avatar-edit input {
	display: none;
}

.avatar-upload .avatar-preview img {
	width: 192px;
	height: 192px;
	position: relative;
	border-radius: 100%;
	border: 6px solid #0000001a;
	box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload .avatar-edit input+label:after {
	content: "";
	color: #757575;
	position: absolute;
	top: 10px;
	left: 0;
	right: 0;
	text-align: center;
	margin: auto;
	background: url('../images/choose.svg');
	width: 50px;
	background-repeat: no-repeat;
	height: 50px;
}

.theme-color {
	background: #3DC8AF;
}

.chat-font {
	width: 35px;
	height: 35px;
	border-radius: 50%;
	background: #3DC8AF;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	border: 1px solid #3DC8AF;
	margin-top: 10px;
}

.chat-font:hover {
	background: white;
	color: #3DC8AF;
	border: 1px solid #3DC8AF;
}

.grid_Services ul{
	display: grid;
	 grid-template-columns: auto auto auto;
	 grid-row-gap: 20px;
	 padding-left: 15px;
  }
  @media (min-width: 768px) and (max-width: 1023px){
	  .grid_Services{
	  grid-template-columns: auto auto;
	}
  }
  @media (max-width: 767px){
	.grid_Services{
	  grid-template-columns: auto;
	}
  }

  .imgFit{
	object-fit: contain;
  }

  .img-fix{
	object-fit: cover;
  }

  .btn-loadMore {
    border-radius: 50px;
    background: #b9b9b9;
    font-weight: 700;
    color: #fff;
    font-size: 15px;
    padding: 6px 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
}

.btn-loadMore:hover {
    border-radius: 50px;
    background: #b9b9b9;
    font-weight: 700;
    color: #fff;
    font-size: 15px;
    padding: 6px 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
}


.btn-books{
	background: #3DC8AF;
	font-weight: 700;
	color: #fff;
	font-size: 16px;
	padding: 6px 20px;

}

.dropdown-item:active { 	
	background: #3DC8AF;
	color: #fff;
}
.checked-price{
	display: block ;
	color: red;
}



/* Hide the default file input */
.file-input {
    display: none;
}

/* Style the label as a button */
/* .file-label {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
} */

/* Style the icon inside the label */
.file-icon {
    margin-right: 10px;
    width: 20px;
    height: 20px;
}


.file-upload label.form-control {
    border: 0.5px solid #dbdbdb !important;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 10px;
}


.contact-form select.form-control {
    border: 0.5px solid #dbdbdb !important;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 10px;
}


select.form-control {
	border: 0.5px solid #dbdbdb !important;
	padding-top: 12px;
	padding-bottom: 12px;
	border-radius: 10px;
}


select.form-select {
	border: 0.5px solid #dbdbdb !important;
	padding-top: 12px;
	padding-bottom: 12px;
	border-radius: 10px;
}
.form-select:focus {
	box-shadow: none;
}
.LoadingActionWrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(30, 30, 46, 0.6);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    z-index: 999;
}
.password-hide-show{
	position: absolute;
	right: 10px;
	top: 50%;
	font-size: 20px;
  }
  .input-width .form-control{
    width: 60px;
    height: 60px;
    text-align: center;
    font-size: 20px;
}


.form-login.otp .form-control{
    padding-left: 0px;
}   


.text-muted{
    color: #3C3C3C;
}

@media (min-width: 1024px) and (max-width: 1200px){
 .input-width .form-control{
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 30px;
    } 
}


@media (min-width: 768px) and (max-width: 1023px){
 .input-width .form-control{
    width: 80px;
    height: 81px;
    text-align: center;
    font-size: 30px;
    }   
}

@media (max-width: 767px){
.input-width .form-control{
    width: 60px;
    height: 61px;
    text-align: center;
    font-size: 30px;
    }  
}


/* Targeting the input with the class "no-spinner" to hide spinner arrows */

/* For WebKit browsers (Chrome, Safari) */
.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* For Firefox */
.no-spinner {
    -moz-appearance: textfield;
}

.close-popup{
    position: relative;
}

.close-popup.btn-close{position: absolute;
    right: -8px !important;
    top: -8px !important;
}
